import React, { useCallback } from 'react'
import suuid from 'short-uuid'

import ErrorScreen from 'src/components/ErrorScreen'
import LoadingScreen from 'src/components/LoadingScreen'
import {
  useNavigateTo,
  SimpleRouteName,
  RouteName,
} from 'src/contexts/navigation'
import useCreateLobbyMutation from 'src/hooks/useCreateLobbyMutation'
import logger from 'src/logger'

import scss from './style.scss'

const suuidTranslator = suuid()

const HomeScreen = () => {
  const [createLobby, { data, pending, error }] = useCreateLobbyMutation()

  const navigateTo = useNavigateTo()

  const onHostGameClick = useCallback(() => {
    createLobby().then((data) => {
      switch (data.status) {
        case 401:
          navigateTo(SimpleRouteName.HOST)
          break
        case 201:
          navigateTo({
            name: RouteName.LOBBY,
            params: {
              slug: suuidTranslator.fromUUID(data.body.id),
            },
          })
          break
        default:
          logger.warn(
            // Handle unsupported status
            // @ts-ignore
            `useHostGameLazyQuery: Unsupported status ${data.status}`
          )
      }
    })
  }, [createLobby, navigateTo])

  const onJoinGameClick = useCallback(() => {
    navigateTo(SimpleRouteName.JOIN)
  }, [navigateTo])

  if (pending) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  return (
    <>
      <h1 className={scss.title}>Lune</h1>
      <pre>{JSON.stringify(data, null, 2)}</pre>
      <div>
        <button onClick={onHostGameClick}>Host a game</button>
      </div>
      <div>
        <button onClick={onJoinGameClick}>Join a game</button>
      </div>
    </>
  )
}

export default HomeScreen
