import React from 'react'

import scss from './style.scss'

const LoadingScreen = () => (
  <>
    <h1 className={scss.title}>Lune</h1>
    <pre>Loading...</pre>
  </>
)

export default LoadingScreen
