import React, { useState, useCallback, FormEvent } from 'react'

import useInputChangeCallback from 'src/hooks/useInputChangeCallback'

import scss from './style.scss'

const HostLobbyScreen = () => {
  const [displayName, setDisplayName] = useState<string>('')
  const onChange = useInputChangeCallback(setDisplayName)
  const [pending, setPending] = useState<boolean>(false)

  const onSubmit = useCallback((event: FormEvent) => {
    event.preventDefault()
    setPending(true)
  }, [])

  const isDisplayNameValid = displayName.length >= 3
  const disabled = pending
  const buttonDisabled = !isDisplayNameValid || disabled

  return (
    <>
      <h1 className={scss.title}>Lune</h1>
      <form onSubmit={onSubmit}>
        <div>
          <label htmlFor="displayName">Your name</label>
          <input
            id="displayName"
            type="text"
            value={displayName}
            disabled={disabled}
            onChange={onChange}
          />
        </div>
        <div>
          <button type="submit" disabled={buttonDisabled}>
            Host
          </button>
        </div>
      </form>
    </>
  )
}

export default HostLobbyScreen
