import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import style from './style.scss'

interface Position {
  x: number
  y: number
}

// TODO Make this work for touch screens
const usePanning = () => {
  const [panPosition, setPanPosition] = useState({ x: 0, y: 0 })
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [panning, setPanning] = useState(false)
  const startPan = useCallback(
    (position: Position) => {
      setPanning(true)
      setPosition({
        x: panPosition.x - position.x,
        y: panPosition.y - position.y,
      })
    },
    [panPosition]
  )

  const updatePanPosition = useCallback(
    (event: MouseEvent) => {
      setPanPosition({
        x: position.x + event.clientX,
        y: position.y + event.clientY,
      })
    },
    [position]
  )

  const stopPanning = useCallback(() => {
    setPanning(false)
  }, [])

  useEffect(() => {
    if (!panning) {
      return
    }

    window.addEventListener('mousemove', updatePanPosition)
    window.addEventListener('mouseup', stopPanning)

    return () => {
      window.removeEventListener('mousemove', updatePanPosition)
      window.removeEventListener('mouseup', stopPanning)
    }
  }, [panning, updatePanPosition, stopPanning])

  return [panPosition, startPan] as [Position, (position: Position) => void]
}

const Pan = ({ children }: { children: ReactNode }) => {
  const [panOffset, startPan] = usePanning()

  const onMouseDown = useCallback(
    (event: React.MouseEvent) => {
      startPan({ x: event.clientX, y: event.clientY })
    },
    [startPan]
  )

  const transform: CSSProperties = useMemo(
    () => ({ transform: `translate(${panOffset.x}px, ${panOffset.y}px)` }),
    [panOffset]
  )

  return (
    <div onMouseDown={onMouseDown} className={style.container}>
      <div className={style.content} style={transform}>
        {children}
      </div>
    </div>
  )
}

export default Pan
