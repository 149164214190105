import React from 'react'

import HomeScreen from 'src/components/HomeScreen'
import HostLobbyScreen from 'src/components/HostLobbyScreen'
import JoinLobbyScreen from 'src/components/JoinLobbyScreen'
import LobbyScreen from 'src/components/LobbyScreen'
import { Provider as ApiProvider } from 'src/contexts/api'
import {
  Provider as NavigationProvider,
  useNavigation,
  SimpleRouteName,
  RouteName,
} from 'src/contexts/navigation'
import createAPI from 'src/createAPI'
import logger from 'src/logger'

import NotFoundScreen from '../NotFoundScreen'

// import createAPI from 'src/createMockAPI'

const Screen = () => {
  const { route } = useNavigation()

  switch (route?.name) {
    case SimpleRouteName.HOME:
      return <HomeScreen />
    case SimpleRouteName.HOST:
      return <HostLobbyScreen />
    case SimpleRouteName.JOIN:
      return <JoinLobbyScreen />
    case RouteName.LOBBY:
      return <LobbyScreen slug={route.params.slug} />
    default:
      logger.warn('Missing component.', route)
  }

  return <NotFoundScreen />
}

const api = createAPI()

const Application = () => (
  <NavigationProvider>
    <ApiProvider api={api}>
      <Screen />
    </ApiProvider>
  </NavigationProvider>
)

export default Application
