import { Subscribable } from 'light-observable'

import createBehaviorFromObservable from 'src/observables/createBehaviorFromObservable'
import createObservableFromAbortableFunction from 'src/observables/createObservableFromAbortableFunction'

import {
  OperateFunction,
  OperationDefinitionMap,
  OperationOptions,
} from '../types'

const ensureOperationSubscribable = <
  OperationMap extends OperationDefinitionMap,
  K extends keyof OperationMap
>(
  oid: K,
  opts: OperationOptions<OperationMap[K]> | null,
  operate: OperateFunction<OperationMap>
): Subscribable<OperationMap[K]['responses']> => {
  return createBehaviorFromObservable<OperationMap[K]['responses']>(
    createObservableFromAbortableFunction<OperationMap[K]['responses']>(
      async (observer, signal) => {
        const resl = await operate(oid, {
          parameters: undefined,
          body: undefined,
          ...opts,
          signal,
        })

        observer.next(resl)
        observer.complete()
      }
    )
  )
}

export default ensureOperationSubscribable
