import cn from 'classnames'
import React, { Fragment, useCallback } from 'react'

import { TileDefinition } from '@jaune/lune-game-core'

import Tile from '../Tile'

import style from './style.scss'

interface TileBarItemProps {
  tile: TileDefinition
  selected: boolean
  disabled?: boolean
  onClick: (tile: TileDefinition | null) => void
}

const TileBarItem = ({
  tile,
  selected,
  disabled,
  onClick,
}: TileBarItemProps) => {
  const onSelect = useCallback(() => {
    onClick(selected ? null : tile)
  }, [onClick, tile, selected])

  return disabled ? (
    <li className={style.disabled}>
      <Tile tileDefinition={tile} showCost={true} />
    </li>
  ) : (
    <li onClick={onSelect} className={cn(selected && style.selected)}>
      <Tile tileDefinition={tile} showCost={true} />
    </li>
  )
}

interface Props {
  tiles: ReadonlyArray<TileDefinition | null>
  selectedTile: TileDefinition | null
  choiceCount: number
  onTileSelect: (tile: TileDefinition | null) => void
}

const TileBar = ({
  tiles,
  selectedTile,
  choiceCount,
  onTileSelect: onTileSelected,
}: Props) => {
  let count = 0

  return (
    <ul className={style.outer}>
      {tiles.map((tile, index) => {
        if (!tile) {
          return <li key={index} className={style.emptySlot} />
        }

        count++

        return (
          <Fragment key={index}>
            <TileBarItem
              tile={tile}
              selected={tile === selectedTile}
              disabled={count > choiceCount}
              onClick={onTileSelected}
            />
            {count === choiceCount && <li className={style.separator} />}
          </Fragment>
        )
      })}
    </ul>
  )
}

export default TileBar
