import { useCallback, ChangeEvent } from 'react'

const useInputChangeCallback = (onChange: (value: string) => void) =>
  useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value)
    },
    [onChange]
  )

export default useInputChangeCallback
