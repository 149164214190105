import { createSubject } from 'light-observable/observable'
import { fromEvent } from 'light-observable/observable/fromEvent'
import { merge } from 'light-observable/observable/merge'
import { map } from 'light-observable/operators'
import { match } from 'path-to-regexp'
import React, {
  createContext,
  useMemo,
  ReactNode,
  useEffect,
  useState,
  useContext,
} from 'react'

import logger from 'src/logger'

export interface Navigation {
  route: Route | null
  state: any | undefined
}

export enum RouteName {
  LOBBY = 'LOBBY',
}

export enum SimpleRouteName {
  HOST = 'HOST',
  JOIN = 'JOIN',
  HOME = 'HOME',
}

export interface SimpleRoute {
  name: SimpleRouteName
}

interface LobbyRoute {
  name: RouteName.LOBBY
  params: {
    slug: string
  }
}

type Route = SimpleRoute | LobbyRoute

const ROUTE_HASH_MAPPING = {
  [SimpleRouteName.HOME]: '#/',
  [SimpleRouteName.HOST]: '#/lobbies/host',
  [SimpleRouteName.JOIN]: '#/lobbies/join',
  [RouteName.LOBBY]: (route: Route) =>
    route.name === RouteName.LOBBY ? `#/lobbies/${route.params.slug}` : null,
}

const MATCHERS = [
  {
    name: RouteName.LOBBY,
    match: match<{ slug: string }>('/lobbies/:slug', {
      sensitive: true,
      strict: true,
    }),
  },
]

const getCurrentRoute = (): Route | null => {
  const path = window.location.hash.substring(1)

  switch (path) {
    case '':
    case '/':
    case '/index':
    case '/home':
      return { name: SimpleRouteName.HOME }
    case '/lobbies/host':
      return { name: SimpleRouteName.HOST }
    case '/lobbies/join':
      return { name: SimpleRouteName.JOIN }
  }

  for (const matcher of MATCHERS) {
    const matches = matcher.match(path)

    if (matches) {
      return {
        name: matcher.name,
        params: matches.params,
      }
    }
  }

  return null
}

const getCurrentNavigation = (): Navigation => {
  return {
    route: getCurrentRoute(),
    state: history.state,
  }
}

interface ContextValue {
  navigateTo: (route: Route | SimpleRouteName) => void
  subscribe: typeof navigation$['subscribe']
}

export const Context = createContext<ContextValue>({
  navigateTo: () => {
    throw new Error('navigation: context not provided.')
  },
  subscribe: () => {
    throw new Error('navigation: context not provided.')
  },
})

interface ProviderProps {
  children: ReactNode
}

const [navigateTo$, sink] = createSubject<Navigation>()

const navigation$ = merge(
  navigateTo$,
  fromEvent(window, 'popstate', false).pipe(map(() => getCurrentNavigation())),
  fromEvent(window, 'hashchange', false).pipe(map(() => getCurrentNavigation()))
)

const buildURLFromRoute = (route: Route): string | null => {
  const match = ROUTE_HASH_MAPPING[route.name]

  if (!match) {
    logger.warn('buildURLFromRoute: Invalid route.', route)
    return null
  }

  const url = new URL(window.location.href)

  if (typeof match === 'string') {
    url.hash = match
  } else if (typeof match === 'function') {
    const hash = match(route)

    if (!hash) {
      logger.warn('buildURLFromRoute: Invalid route.', route)
      return null
    }

    url.hash = hash
  }

  return url.toString()
}

const navigateTo = (input: Route | SimpleRouteName) => {
  const route = typeof input === 'string' ? { name: input } : input
  const url = buildURLFromRoute(route)

  if (!url) {
    logger.warn('navigateTo: Invalid route.', route)
    return null
  }

  history.pushState({ route }, '', url)
  sink.next({
    route,
    state: undefined,
  })
}

export const Provider = ({ children }: ProviderProps) => {
  const value = useMemo(
    () => ({
      subscribe: navigation$.subscribe.bind(navigation$),
      navigateTo,
    }),
    []
  )

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const useNavigation = (): Navigation => {
  const { subscribe } = useContext(Context)
  const [navigation, setNavigation] = useState<Navigation>(() =>
    getCurrentNavigation()
  )

  useEffect(() => {
    const subscription = subscribe((newState) => {
      setNavigation(newState)
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [subscribe])

  return navigation
}

export const useNavigateTo = () => {
  const { navigateTo } = useContext(Context)

  return navigateTo
}
