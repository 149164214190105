import { Observable, SubscriptionObserver } from 'light-observable'

type AbortableFunction<T> = (
  observer: SubscriptionObserver<T>,
  signal: AbortSignal
) => Promise<void>

const createObservableFromAbortableFunction = <T>(fn: AbortableFunction<T>) =>
  new Observable((observer: SubscriptionObserver<T>) => {
    const controller = new AbortController()

    const timeout = setTimeout(() => {
      fn(observer, controller.signal).catch((error) => {
        observer.error(error)
        observer.complete()
      })
    }, 0)

    return () => {
      clearTimeout(timeout)
      if (!observer.closed) {
        controller.abort()
      }
    }
  })

export default createObservableFromAbortableFunction
