import createBasic from './createBasic'
import {
  Handle,
  BasicConfiguration,
  OperateFunctionOptions,
  OperationsDefinition,
} from './types'

interface Configuration extends BasicConfiguration {
  host: string
  secure?: boolean
}

const createAPI = <A extends OperationsDefinition>(
  cfg: Configuration
): Handle<A> => {
  const { host, secure = true } = cfg
  const origin = `${secure ? 'https' : 'http'}://${host}`
  const api = createBasic(cfg)

  const operate = <
    OperationMap extends A['mutations'] & A['queries'],
    K extends keyof OperationMap
  >(
    oid: K,
    options: OperateFunctionOptions<OperationMap[K]>
  ): Promise<OperationMap[K]['responses']> => {
    // @ts-ignore
    const op = api.operations[oid]

    if (!op) {
      throw new Error(`API: operation unsupported: ${oid}`)
    }

    return window.fetch(`${origin}${op.path}`, {
      method: op.method,
      credentials: 'omit',
      mode: 'cors',
      signal: options.signal,
    })
  }

  return {
    ...api,
    operate,
  }
}

export default createAPI
