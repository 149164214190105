import { isRight } from 'fp-ts/Either'
import * as t from 'io-ts'
import reporter from 'io-ts-reporters'

const tBearerSecurityScheme = t.type({
  type: t.literal('http'),
  scheme: t.literal('bearer'),
})

type Schema =
  | {
      type: 'string' | 'integer' | 'number' | 'boolean'
    }
  | {
      type: 'array'
      items: Schema
    }
  | {
      type: 'object'
      properties: Record<string, Schema>
      required?: Array<string>
    }
  | {
      $ref: string
    }

const tSchema: t.Type<Schema> = t.recursion('tSchema', () =>
  t.union([
    t.type({
      type: t.literal('string'),
    }),
    t.type({
      type: t.literal('integer'),
    }),
    t.type({
      type: t.literal('number'),
    }),
    t.type({
      type: t.literal('boolean'),
    }),
    t.type({
      type: t.literal('array'),
      items: tSchema,
    }),
    t.type({
      type: t.literal('object'),
      properties: t.record(t.string, tSchema),
    }),
    t.type({
      type: t.literal('object'),
      properties: t.record(t.string, tSchema),
      required: t.array(t.string),
    }),
    tReference,
  ])
)

const tReference = t.type({
  $ref: t.string,
})

const tContent = t.type({
  schema: tSchema,
})

const tResponse = t.type({
  description: t.string,
  content: t.record(t.string, tContent),
})

const tParameter = t.type({
  name: t.string,
  in: t.literal('path'),
  schema: tSchema,
  required: t.boolean,
  description: t.string,
})

export type Parameter = t.TypeOf<typeof tParameter>

const tOperation = t.type({
  operationId: t.string,
  security: t.array(t.record(t.string, t.array(t.string))),
  responses: t.record(t.string, t.union([tReference, tResponse])),
  parameters: t.union([t.array(tParameter), t.undefined]),
})

const tSecurityScheme = tBearerSecurityScheme

const tDefinition = t.type({
  openapi: t.string, // TODO: refine semantic version
  info: t.type({
    title: t.string,
    version: t.string, // TODO: refine semantic version
  }),
  components: t.type({
    securitySchemes: t.record(t.string, tSecurityScheme),
    schemas: t.record(t.string, tSchema),
  }),
  paths: t.record(t.string, t.record(t.string, tOperation)),
})

type Definition = t.TypeOf<typeof tDefinition>

export const verify = (data: unknown): Definition => {
  const decoded = tDefinition.decode(data)

  if (isRight(decoded)) {
    return decoded.right
  }

  throw new Error(reporter.report(decoded).join('\n'))
}

export default {
  verify,
}
