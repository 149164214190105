import {
  Observable,
  PartialObserver,
  Subscribable,
  Subscription,
} from 'light-observable'

interface NormalizedArgs<T> {
  next?: (value: T) => void
  error?: (reason: any) => void
  complete?: () => void
}

const normalizeArgs = <T extends unknown>(
  next?: PartialObserver<T> | ((value: T) => void),
  error?: (reason: any) => void,
  complete?: () => void
): NormalizedArgs<T> => {
  if (next && typeof next !== 'function') {
    return {
      next: next.next?.bind(next),
      error: next.error?.bind(next),
      complete: next.complete?.bind(next),
    }
  }

  return {
    next,
    error,
    complete,
  }
}

const createBehaviorFromObservable = <T extends unknown>(
  input$: Observable<T>
): Subscribable<T> => {
  let subscription: Subscription | null = null
  let observers: Array<NormalizedArgs<T>> = []
  const proxy = {
    next: (value: T) => {
      observers.forEach(({ next }) => {
        next && next(value)
      })
    },
    error: (reason: any) => {
      observers.forEach(({ error }) => {
        error && error(reason)
      })
    },
    complete: () => {
      observers.forEach(({ complete }) => {
        complete && complete()
      })
      // reset state
      subscription = null
      observers = []
    },
  }

  return {
    subscribe: (
      next?: PartialObserver<T> | ((value: T) => void),
      error?: (reason: any) => void,
      complete?: () => void
    ): Subscription => {
      const obs = normalizeArgs(next, error, complete)

      if (!subscription) {
        subscription = input$.subscribe(proxy)
        observers.push(obs)
      }

      return {
        unsubscribe: () => {
          observers = observers.filter((o) => o !== obs)

          if (subscription && observers.length <= 0) {
            subscription.unsubscribe()
            subscription = null
          }
        },
      }
    },
  }
}

export default createBehaviorFromObservable
