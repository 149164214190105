import React from 'react'

import scss from './style.scss'

const NotFoundScreen = () => {
  return (
    <>
      <h1 className={scss.title}>Lune</h1>
      <div>Not Found</div>
    </>
  )
}

export default NotFoundScreen
