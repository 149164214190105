import _createAPI from 'src/contexts/api/createAPI'
import definition from 'src/openapi.yml'

const createAPI = () =>
  _createAPI({
    definition: definition,
    host: process.env.LUNE_API_HOST || 'localhost:3000',
    secure: process.env.LUNE_API_SECURE !== 'false',
  })

export default createAPI
