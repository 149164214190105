import React from 'react'
import ReactDOM from 'react-dom'

import Application from './components/Application'

import scss from './style.scss'

const root = document.createElement('DIV')

if (scss.root) {
  root.setAttribute('class', scss.root)
}

document.body.appendChild(root)

ReactDOM.render(<Application />, root)
