import { Subscription } from 'light-observable'
import { useCallback, useEffect, useState, useRef } from 'react'

import { useContext } from '../index'
import {
  OperationDefinition,
  OperationDefinitionMap,
  MutationOptions,
  QueryResult,
} from '../types'

interface MutationFuture<TData> {
  data: TData | null
  promise: Promise<TData>
  subscription: Subscription
}

type MutationTrigger<D extends OperationDefinition> = (
  options?: MutationOptions<D>
) => Promise<D['responses']>
type MutationTuple<D extends OperationDefinition> = Readonly<
  [MutationTrigger<D>, QueryResult<D['responses']>]
>

const createMutationHook = <
  OperationMap extends OperationDefinitionMap,
  K extends keyof OperationMap
>(
  operationId: K
) => (): MutationTuple<OperationMap[K]> => {
  const { ensureMutationSubscribable } = useContext()
  const [result, setResult] = useState<
    QueryResult<OperationMap[K]['responses']>
  >(() => ({}))
  const future = useRef<MutationFuture<OperationMap[K]['responses']> | null>(
    null
  )

  useEffect(
    () => () => {
      // We don't want useEffect to be trigger when future.current change
      // eslint-disable-next-line react-hooks/exhaustive-deps
      future.current?.subscription.unsubscribe()
    },
    [future]
  )

  return [
    useCallback(
      (options?: MutationOptions<OperationMap[K]>) => {
        if (future.current) {
          return future.current.promise
        }

        const opts = {
          parameters: undefined,
          body: undefined,
          ...options,
        }
        const obs = ensureMutationSubscribable<OperationMap, K>(
          operationId,
          opts
        )

        setResult({ pending: true })

        const promise = new Promise<OperationMap[K]['responses']>(
          (resolve, reject) => {
            future.current = {
              data: null,
              promise,
              subscription: obs.subscribe({
                next: (data) => {
                  setResult({ data })
                  if (future.current) {
                    future.current.data = data || null
                  }
                },
                error: (error) => {
                  setResult({
                    error,
                  })
                  reject(error)
                  future.current = null
                },
                complete: () => {
                  if (future.current?.data) {
                    resolve(future.current?.data)
                  }
                  future.current = null
                },
              }),
            }
          }
        )

        return promise
      },
      [future, ensureMutationSubscribable]
    ),
    result,
  ]
}

export default createMutationHook
