import cn from 'classnames'
import React, { useMemo } from 'react'

import {
  TileColor,
  TileObjective as TileObjectiveModel,
} from '@jaune/lune-game-core'

import style from './style.scss'

interface Props {
  objective: TileObjectiveModel
  completed?: boolean
}

const TileObjective = ({ objective, completed }: Props) => {
  const colors = useMemo(() => {
    const colors = []

    for (const key in objective) {
      const count = objective[key as TileColor] || 0
      for (let i = 0; i < count; i++) {
        colors.push(key)
      }
    }

    return colors
  }, [objective])

  return (
    <div className={cn(style.objective, completed && style.completed)}>
      {colors.map((color, index) => (
        <div key={index} className={style[color.toLowerCase()]} />
      ))}
    </div>
  )
}

export default TileObjective
