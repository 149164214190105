import React, { ComponentType } from 'react'
import suuid from 'short-uuid'
import { validate as validateUUID } from 'uuid'

import NotFoundScreen from 'src/components/NotFoundScreen'
import logger from 'src/logger'

const suuidTranslator = suuid()

const convertSlugToUUID = (slug: string): string | null => {
  if (validateUUID(slug)) {
    return slug
  }

  try {
    const id = suuidTranslator.toUUID(slug)

    if (validateUUID(id)) {
      return id
    }
  } catch (error) {
    logger.warn('convertSlugToUUID:', error)
  }

  return null
}

const withLobbySlugCheck = () => <P extends { slug: string }>(
  Comp: ComponentType<P>
) =>
  // TODO: HOC displayName :(
  // eslint-disable-next-line react/display-name
  (props: P) => {
    const id = convertSlugToUUID(props.slug)

    if (!id) {
      return <NotFoundScreen />
    }

    return <Comp {...props} slug={id} />
  }

export default withLobbySlugCheck
