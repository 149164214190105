import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  canRefillTiles,
  getCurrentPlayer,
  TileDefinition,
  TILE_CHOICE_COUNT,
} from '@jaune/lune-game-core'
import { GameState } from '@jaune/lune-game-core/lib/models/GameState'

import usePostLobbyActionMutation from 'src/hooks/usePostLobbyActionMutation'

import ErrorScreen from '../ErrorScreen'
import LoadingScreen from '../LoadingScreen'
import { Lobby } from '../LobbyScreen'

import Pan from './Pan'
import PlayerSideBar from './PlayerSideBar'
import PlayerTrack from './PlayerTrack'
import TileBar from './TileBar'
import TileGrid, { Position } from './TileGrid'

import style from './style.scss'

interface UIState {
  readonly selectedPlayerId: string
  readonly selectedTile: TileDefinition | null
  readonly selectedPosition: Position | null
  readonly positionPreview: number | null
}

interface Props {
  lobbyId: string
  ownedPlayersIds: string[]
  gameState: GameState
  lobbyChange: (lobby: Lobby) => void
}

const InGameScreen = ({
  lobbyId,
  ownedPlayersIds,
  gameState,
  lobbyChange,
}: Props) => {
  const [postLobbyAction, { pending, error }] = usePostLobbyActionMutation()

  const [uiState, setUIState] = useState<UIState>(() => ({
    selectedTile: null,
    selectedPosition: null,
    selectedPlayerId: getCurrentPlayer(gameState.players).id,
    positionPreview: null,
  }))

  useEffect(() => {
    setUIState((prevState) => ({
      ...prevState,
      selectedPlayerId: getCurrentPlayer(gameState.players).id,
    }))
  }, [gameState])

  const canEndTurn = !!uiState.selectedTile && !!uiState.selectedPosition
  const canRefill = useMemo(() => gameState && canRefillTiles(gameState), [
    gameState,
  ])
  const tiles = useMemo(() => {
    const player =
      gameState &&
      gameState.players.find((player) => player.id === uiState.selectedPlayerId)

    return player ? player.tiles : null
  }, [gameState, uiState])
  const isOwner = useMemo(() => {
    return ownedPlayersIds.includes(uiState.selectedPlayerId)
  }, [uiState, ownedPlayersIds])
  const isCurrentPlayer = useMemo(() => {
    return uiState.selectedPlayerId === getCurrentPlayer(gameState.players).id
  }, [uiState, gameState])

  const onEndTurnClick = useCallback(() => {
    if (!uiState.selectedTile || !uiState.selectedPosition) {
      return
    }

    setUIState((prevState) => ({
      ...prevState,
      selectedTile: null,
      selectedPosition: null,
      positionPreview: null,
    }))
    postLobbyAction({
      parameters: { id: lobbyId },
      body: {
        type: 'PLACE_TILE',
        index: gameState.tileWheel
          .filter((tile) => !!tile)
          .indexOf(uiState.selectedTile),
        x: uiState.selectedPosition.x,
        y: uiState.selectedPosition.y,
      },
    }).then((data) => {
      if (data.status !== 200) {
        return
      }

      lobbyChange(data.body)
    })
  }, [postLobbyAction, lobbyChange, lobbyId, uiState, gameState])

  const onRefillClick = useCallback(() => {
    if (!canRefill) {
      return
    }

    postLobbyAction({
      parameters: { id: lobbyId },
      body: {
        type: 'REFILL',
      },
    }).then((data) => {
      if (data.status !== 200) {
        return
      }

      lobbyChange(data.body)
    })
  }, [postLobbyAction, lobbyChange, canRefill, lobbyId])

  const onTileSelect = useCallback(
    (selectedTile: TileDefinition | null) => {
      const player = getCurrentPlayer(gameState.players)

      const positionPreview =
        player && selectedTile
          ? player.position.index + selectedTile.cost
          : null

      setUIState((prevState) => ({
        ...prevState,
        selectedTile,
        positionPreview,
      }))
    },
    [gameState]
  )

  const onTilePlaced = useCallback((selectedPosition: Position | null) => {
    setUIState((prevState) => ({
      ...prevState,
      selectedPosition,
    }))
  }, [])

  const onPlayerClick = useCallback((id: string) => {
    setUIState((prevState) => ({
      ...prevState,
      selectedPlayerId: id,
    }))
  }, [])

  if (pending || !gameState) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  return (
    <>
      <div className={style.screen}>
        <div className={style.playerGrid}>
          {tiles && (
            <Pan>
              <TileGrid
                tiles={tiles}
                selectedTile={uiState.selectedTile}
                selectedPosition={uiState.selectedPosition}
                onTilePlaced={onTilePlaced}
                readonly={!isOwner || !isCurrentPlayer}
              />
            </Pan>
          )}
        </div>
        <div className={style.playerTrack}>
          <PlayerTrack
            playersPositions={gameState.players}
            previewPosition={uiState.positionPreview}
          />
        </div>
        <div className={style.tileBar}>
          <TileBar
            tiles={gameState.tileWheel}
            selectedTile={uiState.selectedTile}
            choiceCount={TILE_CHOICE_COUNT}
            onTileSelect={onTileSelect}
          />
        </div>
        <div className={style.playerSideBar}>
          <PlayerSideBar
            players={gameState.players}
            selectedPlayerId={uiState.selectedPlayerId}
            onPlayerClick={onPlayerClick}
          />
        </div>
        <div className={style.footer}>
          <button
            className={style.refillButton}
            onClick={onRefillClick}
            disabled={!canRefill}
          >
            REFILL
          </button>
          <button
            className={style.confirmButton}
            onClick={onEndTurnClick}
            disabled={!canEndTurn}
          >
            END TURN
          </button>
        </div>
      </div>
    </>
  )
}

export default InGameScreen
