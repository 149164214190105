import React from 'react'

import scss from './style.scss'

interface Props {
  error?: any
}

const ErrorScreen = ({ error }: Props) => (
  <>
    <h1 className={scss.title}>Lune</h1>
    <pre>Oups...</pre>
    {error && <pre>{error.message}</pre>}
  </>
)

export default ErrorScreen
