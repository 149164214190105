import React from 'react'

import scss from './style.scss'

const JoinLobbyScreen = () => {
  return (
    <>
      <h1 className={scss.title}>Lune</h1>
      <pre>JoinLobbyScreen</pre>
    </>
  )
}

export default JoinLobbyScreen
