import React, { useCallback } from 'react'

import avatar from './avatars/duck.png'

interface Info {
  nickname: string
  avatar: string
}

interface PlayerCardProps {
  info: Info
  infoChange: (player: Info) => void
}

const PlayerCard = ({ info, infoChange }: PlayerCardProps) => {
  const onNicknameChange = useCallback(
    (nickname) => {
      infoChange({
        ...info,
        nickname,
      })
    },
    [info, infoChange]
  )

  return (
    <>
      <img src={avatar} />
      <input
        value={info.nickname}
        onChange={onNicknameChange}
        type="text"
        autoComplete="chrome-off"
      />
    </>
  )
}

interface Props {
  playerInfo: Info
  playerInfoChange?: (player: Info) => void
}

const PlayerSlot = ({ playerInfo, playerInfoChange }: Props) => {
  const onInfoChange = useCallback(
    (info: Info) => {
      if (playerInfoChange) {
        playerInfoChange(info)
      }
    },
    [playerInfoChange]
  )

  if (!playerInfo) {
    return null
  }

  return <PlayerCard info={playerInfo} infoChange={onInfoChange} />
}

export default PlayerSlot
