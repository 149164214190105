import cn from 'classnames'
import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'

import { TileDefinition, Tile as TileModel } from '@jaune/lune-game-core'

import Tile from '../Tile'

import style from './style.scss'

const TILE_GAP_PX = 4
const TILE_SIZE_PX = 100

export interface Position {
  readonly x: number
  readonly y: number
}

function gridToPixel(coord: number) {
  return (coord - 0.5) * (TILE_SIZE_PX + TILE_GAP_PX)
}

function buildStyleFromPosition(position: Position | null): CSSProperties {
  if (!position) {
    return {
      left: 0,
      top: 0,
      width: TILE_SIZE_PX,
      height: TILE_SIZE_PX,
      display: 'none',
    }
  }

  return {
    left: gridToPixel(position.x),
    top: gridToPixel(position.y),
    width: TILE_SIZE_PX,
    height: TILE_SIZE_PX,
    display: 'block',
  }
}

interface GridItemProps {
  children: ReactNode
  position: Position
  animate?: boolean
  onMouseHoverChange?: (position: Position | null) => void
  onItemClick?: (position: Position) => void
}

const GridItem = ({
  position,
  children,
  onMouseHoverChange,
  onItemClick,
  animate,
}: GridItemProps) => {
  const itemStyle = useMemo(() => buildStyleFromPosition(position), [position])

  const onMouseEnter = useCallback(() => {
    onMouseHoverChange && onMouseHoverChange(position)
  }, [onMouseHoverChange, position])

  const onMouseLeave = useCallback(() => {
    onMouseHoverChange && onMouseHoverChange(null)
  }, [onMouseHoverChange])

  const onClick = useCallback(() => {
    onItemClick && onItemClick(position)
  }, [onItemClick, position])

  return (
    position && (
      <div
        className={cn(style.item, animate && style.animate)}
        style={itemStyle}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      >
        {children}
      </div>
    )
  )
}

interface TileGridProps {
  tiles: readonly TileModel[]
  selectedTile: TileDefinition | null
  selectedPosition: Position | null
  onTilePlaced: (position: Position | null) => void
  readonly?: boolean
}

const TileGrid = ({
  tiles,
  selectedTile,
  selectedPosition,
  onTilePlaced,
  readonly,
}: TileGridProps) => {
  const tileSlots = useMemo(() => {
    if (!tiles.length) {
      return [{ x: 0, y: 0 }]
    }

    return tiles.reduce((acc, tile, _, tiles) => {
      const slots: Position[] = []

      const positionAvailable = (position: Position) => {
        const tile = tiles.find(
          (tile) => tile.x === position.x && tile.y === position.y
        )
        const slot = acc.find((p) => p.x === position.x && p.y === position.y)

        return !tile && !slot
      }

      const northPos = { x: tile.x, y: tile.y - 1 }
      if (positionAvailable(northPos)) {
        slots.push(northPos)
      }
      const eastPost = { x: tile.x + 1, y: tile.y }
      if (positionAvailable(eastPost)) {
        slots.push(eastPost)
      }
      const southPos = { x: tile.x, y: tile.y + 1 }
      if (positionAvailable(southPos)) {
        slots.push(southPos)
      }
      const westPos = { x: tile.x - 1, y: tile.y }
      if (positionAvailable(westPos)) {
        slots.push(westPos)
      }

      return acc.concat(slots)
    }, [] as Position[])
  }, [tiles])

  const [tileGhostPosition, setTileGhostPosition] = useState<Position | null>(
    null
  )

  const updateGhostPosition = useCallback((position: Position | null): void => {
    setTileGhostPosition(position)
  }, [])

  const placeTile = useCallback(
    (position: Position) => {
      if (!selectedTile) {
        return
      }

      onTilePlaced(position)
    },
    [onTilePlaced, selectedTile]
  )

  const removeTile = useCallback(() => {
    onTilePlaced(null)
  }, [onTilePlaced])

  return (
    <div className={style.grid}>
      {tiles.map((tile) => (
        <GridItem key={`${tile.x}-${tile.y}`} position={tile}>
          <Tile
            tileDefinition={tile.tileDefinition}
            completedMap={tile.completedObjectivesMap}
          />
        </GridItem>
      ))}

      {!readonly &&
        tileSlots.map((position) => (
          <GridItem
            key={`${position.x}-${position.y}`}
            position={position}
            onMouseHoverChange={updateGhostPosition}
            onItemClick={placeTile}
          >
            <div className={style.dropArea} />
          </GridItem>
        ))}

      {!readonly && selectedTile && tileGhostPosition && (
        <GridItem position={tileGhostPosition}>
          <div className={style.ghost}>
            <Tile tileDefinition={selectedTile} />
          </div>
        </GridItem>
      )}

      {!readonly && selectedTile && selectedPosition && (
        <GridItem
          position={selectedPosition}
          animate={true}
          onItemClick={removeTile}
        >
          <div className={style.highlight}>
            <Tile tileDefinition={selectedTile} />
          </div>
        </GridItem>
      )}
    </div>
  )
}

export default TileGrid
