import { range } from 'fp-ts/lib/Array'
import React, { useMemo } from 'react'

import { TrackPosition } from '@jaune/lune-game-core'

import style from './style.scss'

export interface PlayerPosition {
  readonly id: string
  readonly position: TrackPosition
}

interface TrackTileProps {
  players: ReadonlyArray<{ id: string }>
  preview: boolean
}

const TrackTile = ({ players, preview }: TrackTileProps) => {
  return (
    <div className={style.tile}>
      {players.map((player) => (
        <div key={player.id} className={style.token}>
          {player.id}
        </div>
      ))}
      {preview && <div className={style.preview} />}
    </div>
  )
}

const sortByPosition = (a: PlayerPosition, b: PlayerPosition): number => {
  if (a.position.index > b.position.index) {
    return 1
  }
  if (a.position.index < b.position.index) {
    return -1
  }

  return a.position.order - b.position.order
}

const playersAtPosition = (
  playersPositions: ReadonlyArray<PlayerPosition>,
  position: number
): ReadonlyArray<PlayerPosition> => {
  return playersPositions.filter((p) => p.position.index === position)
}

interface PlayerTrackProps {
  readonly playersPositions: ReadonlyArray<PlayerPosition>
  readonly previewPosition: number | null
}

const PlayerTrack = ({
  playersPositions,
  previewPosition,
}: PlayerTrackProps) => {
  const tiles = useMemo(() => {
    if (!playersPositions.length) {
      return []
    }

    const sorted = playersPositions.concat().sort(sortByPosition)

    return range(
      sorted[0].position.index,
      Math.max(sorted[sorted.length - 1].position.index, previewPosition || 0)
    ).map((position) => ({
      position,
      players: playersAtPosition(playersPositions, position)
        .concat()
        .sort(sortByPosition)
        .reverse(),
    }))
  }, [playersPositions, previewPosition])

  return (
    <div className={style.track}>
      {tiles.map((tile, index) => (
        <TrackTile
          key={index}
          players={tile.players}
          preview={previewPosition === tile.position}
        />
      ))}
    </div>
  )
}

export default PlayerTrack
