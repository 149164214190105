import React, { ComponentType } from 'react'

import AuthenticationScreen from 'src/components/AuthenticationScreen'
import ErrorScreen from 'src/components/ErrorScreen'
import LoadingScreen from 'src/components/LoadingScreen'
import { QueryResult } from 'src/contexts/api/types'
import { createQueryEffect } from 'src/openapi-yml'

// import decodeMeResponseBody from 'src/utils/decodeMeResponseBody'

// const requestMe = async (query: Query) => {
//   const response = await query({
//     method: 'GET',
//     pathname: '/me',
//   })

//   if (response.status != 200) {
//     throw new Error(`requestMe code=${response.status}`)
//   }

//   const body = await response.json()

//   const decoded = decodeMeResponseBody(body)

//   if (!decoded) {
//     throw new Error('Invalid format')
//   }

//   return decoded
// }

const useMeQuery = createQueryEffect('getMe')

const useIsAuthenticatedQuery = (): QueryResult<boolean> => {
  const { data, pending, error } = useMeQuery()

  return {
    pending,
    error,
    data: !!data,
  }
}

const withAuthenticationCheck = () => <P extends {}>(Comp: ComponentType<P>) =>
  // TODO: HOC displayName :(
  // eslint-disable-next-line react/display-name
  (props: P) => {
    const { data, pending, error } = useIsAuthenticatedQuery()

    if (pending) {
      return <LoadingScreen />
    }

    if (error) {
      return <ErrorScreen error={error} />
    }

    if (!data) {
      return <AuthenticationScreen />
    }

    return <Comp {...props} />
  }

export default withAuthenticationCheck
