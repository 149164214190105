import flatten from 'lodash/flatten'

import API, { Parameter } from 'src/modules/api'

import { BasicConfiguration, UpdateSecutity } from './types'

interface FlatOperation {
  id: string
  method: string
  path: string
  parameters: Array<Parameter>
}

export const parseTemplateURL = (path: string) => {
  const matches = path.match(/(\{[a-z0-9_]+\})/gi)

  if (!matches) {
    return []
  }

  return matches.map((m) => m.substr(1, m.length - 2))
}

const createBasic = <OperationMap extends unknown>({
  definition,
}: BasicConfiguration) => {
  const verifiedDefinition = API.verify(definition)

  const securitySchemes = verifiedDefinition.components.securitySchemes

  const secutityData: Record<string, UpdateSecutity> = {}

  const updateSecutity = (scheme: string, data: UpdateSecutity) => {
    if (!securitySchemes[scheme]) {
      throw Error(`updateSecutity: invalid scheme ${scheme}`)
    }

    secutityData[scheme] = data
  }

  const operations = flatten(
    Object.entries(verifiedDefinition.paths).map(([path, methods]) => {
      const urlParameters = parseTemplateURL(path)

      return Object.entries(methods).map(
        ([method, operation]): FlatOperation => {
          const parameters = operation.parameters || []

          // check path parameters
          const pathParameterNames = parameters
            .filter((p) => p.in === 'path')
            .map(({ name }) => name)

          pathParameterNames.forEach((name) => {
            if (!urlParameters.includes(name)) {
              throw Error(`Parameter ${name} is missing in path ${path}`)
            }
          })

          urlParameters.forEach((name) => {
            if (!pathParameterNames.includes(name)) {
              throw Error(`Parameter ${name} is not define`)
            }
          })

          return {
            method: method.toUpperCase(),
            path,
            id: operation.operationId,
            parameters: operation.parameters || [],
          }
        }
      )
    })
  )
    .sort((a, b) => a.id.localeCompare(b.id))
    .reduce((all, op) => {
      // @ts-ignore
      if (all[op.id]) {
        throw new Error(`Duplicate operation ${op.id}`)
      }

      // @ts-ignore
      all[op.id] = op

      return all
    }, {} as Record<keyof OperationMap, FlatOperation>)

  return {
    operations,
    updateSecutity,
  }
}

export default createBasic
