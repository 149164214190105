import { Subscribable } from 'light-observable'
import React, {
  createContext,
  useMemo,
  ReactNode,
  useContext as useContextReact,
} from 'react'

import ensureOperationSubscribable from './observables/ensureOperationSubscribable'
import {
  Handle,
  MutationOptions,
  OperationDefinitionMap,
  OperationsDefinition,
  QueryOptions,
} from './types'

type EnsureQuerySubscribableFunction = <
  OperationMap extends OperationDefinitionMap,
  K extends keyof OperationMap
>(
  oid: K,
  opts: QueryOptions<OperationMap[K]['parameters']> | null
) => Subscribable<OperationMap[K]['responses']>

type EnsureMutationSubscribableFunction = <
  OperationMap extends OperationDefinitionMap,
  K extends keyof OperationMap
>(
  oid: K,
  opts: MutationOptions<OperationMap[K]['parameters']>
) => Subscribable<OperationMap[K]['responses']>

interface ContextValue {
  ensureQuerySubscribable: EnsureQuerySubscribableFunction
  ensureMutationSubscribable: EnsureMutationSubscribableFunction
}

const createContextValue = <O extends OperationsDefinition>(
  api: Handle<O>
) => ({
  ensureQuerySubscribable: <K extends keyof O['queries']>(
    oid: K,
    opts: QueryOptions<O['queries'][K]>
  ) => ensureOperationSubscribable<O['queries'], K>(oid, opts, api.operate),
  ensureMutationSubscribable: <K extends keyof O['mutations']>(
    oid: K,
    opts: MutationOptions<O['mutations'][K]>
  ) => ensureOperationSubscribable<O['mutations'], K>(oid, opts, api.operate),
})

export const Context = createContext<ContextValue>({
  ensureQuerySubscribable: () => {
    throw new Error('api: context not provided.')
  },
  ensureMutationSubscribable: () => {
    throw new Error('api: context not provided.')
  },
})

interface ProviderProps<A extends OperationsDefinition> {
  api: Handle<A>
  children: ReactNode
}

export const Provider = <A extends OperationsDefinition>({
  api,
  children,
}: ProviderProps<A>) => {
  const value = useMemo(() => createContextValue(api), [api])

  // @ts-ignore
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const useContext = () => useContextReact(Context)
