import cn from 'classnames'
import React, { useCallback, useMemo } from 'react'

import { PlayerState, Tile } from '@jaune/lune-game-core'
import { calculateScore } from '@jaune/lune-game-core'

import style from './style.scss'

interface PlayerAvatarProps {
  id: string
  selected: boolean
  onClick: (id: string) => void
}

const PlayerAvatar = ({ id, selected, onClick }: PlayerAvatarProps) => {
  const onPlayerClick = useCallback(() => {
    onClick(id)
  }, [onClick, id])

  return (
    <div
      className={cn(style.avatar, selected && style.selected)}
      onClick={onPlayerClick}
    >
      {id}
    </div>
  )
}

const PlayerScore = ({ tiles }: { tiles: ReadonlyArray<Tile> }) => {
  const score = useMemo(() => calculateScore(tiles), [tiles])

  return <div className={style.score}>Score: {score}</div>
}

interface PlayerSideBarProps {
  players: ReadonlyArray<PlayerState>
  selectedPlayerId: string
  onPlayerClick: (playerId: string) => void
}

const PlayerSideBar = ({
  players,
  selectedPlayerId,
  onPlayerClick,
}: PlayerSideBarProps) => {
  return (
    <ul className={style.playerList}>
      {players.map((player) => (
        <li key={player.id}>
          <PlayerAvatar
            id={player.id}
            selected={selectedPlayerId === player.id}
            onClick={onPlayerClick}
          />
          <PlayerScore tiles={player.tiles} />
        </li>
      ))}
    </ul>
  )
}

export default PlayerSideBar
