import React from 'react'

import { TileDefinition } from '@jaune/lune-game-core'

import TileObjective from './TileObjective'

import style from './style.scss'

interface TileProps {
  tileDefinition: TileDefinition
  showCost?: boolean
  completedMap?: ReadonlyArray<boolean>
}

const Tile = ({ tileDefinition, showCost, completedMap }: TileProps) => {
  return (
    <div className={style[tileDefinition.color.toLowerCase()]}>
      {tileDefinition.objectives.map((objective, index) => {
        const completed = completedMap && completedMap[index]

        return (
          <TileObjective
            key={index}
            objective={objective}
            completed={completed}
          />
        )
      })}
      {showCost && <div className={style.cost}>{tileDefinition.cost}</div>}
    </div>
  )
}

export default Tile
