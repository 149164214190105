// GENERATED FILE
import createMutationHook_ from 'src/contexts/api/functions/createMutationHook'
import createQueryEffect_ from 'src/contexts/api/functions/createQueryHook'
import { OperationResponse } from 'src/contexts/api/types'

type QueryMap = {
  getMe: {
    responses:
      | OperationResponse<
          200,
          { readonly id: string; readonly displayName: string }
        >
      | OperationResponse<401, { readonly message?: string }>
  }
  getLobby: {
    responses:
      | OperationResponse<
          200,
          {
            readonly id: string
            readonly players: ReadonlyArray<{
              readonly id: string
              readonly nickname: string
              readonly avatar: string
              readonly ingameId?: string
            }>
            readonly gameState?: null | {
              readonly tileSupply: ReadonlyArray<{
                readonly id: string
                readonly color: 'BLUE' | 'CYAN' | 'RED' | 'YELLOW'
                readonly cost: number
                readonly objectives: ReadonlyArray<{
                  readonly BLUE?: number
                  readonly CYAN?: number
                  readonly RED?: number
                  readonly YELLOW?: number
                }>
              }>
              readonly tileWheel: ReadonlyArray<null | {
                readonly id: string
                readonly color: 'BLUE' | 'CYAN' | 'RED' | 'YELLOW'
                readonly cost: number
                readonly objectives: ReadonlyArray<{
                  readonly BLUE?: number
                  readonly CYAN?: number
                  readonly RED?: number
                  readonly YELLOW?: number
                }>
              }>
              readonly players: ReadonlyArray<{
                readonly id: string
                readonly tiles: ReadonlyArray<{
                  readonly x: number
                  readonly y: number
                  readonly completedObjectivesMap: ReadonlyArray<boolean>
                  readonly tileDefinition: {
                    readonly id: string
                    readonly color: 'BLUE' | 'CYAN' | 'RED' | 'YELLOW'
                    readonly cost: number
                    readonly objectives: ReadonlyArray<{
                      readonly BLUE?: number
                      readonly CYAN?: number
                      readonly RED?: number
                      readonly YELLOW?: number
                    }>
                  }
                }>
                readonly position: {
                  readonly index: number
                  readonly order: number
                }
              }>
              readonly scoreLimit: number
              readonly gameOver?: boolean
              readonly winnerId?: string
            }
          }
        >
      | OperationResponse<403, { readonly message?: string }>
    parameters: { id: string }
  }
}

type MutationMap = {
  postLobbyAction: {
    responses:
      | OperationResponse<
          200,
          {
            readonly id: string
            readonly players: ReadonlyArray<{
              readonly id: string
              readonly nickname: string
              readonly avatar: string
              readonly ingameId?: string
            }>
            readonly gameState?: null | {
              readonly tileSupply: ReadonlyArray<{
                readonly id: string
                readonly color: 'BLUE' | 'CYAN' | 'RED' | 'YELLOW'
                readonly cost: number
                readonly objectives: ReadonlyArray<{
                  readonly BLUE?: number
                  readonly CYAN?: number
                  readonly RED?: number
                  readonly YELLOW?: number
                }>
              }>
              readonly tileWheel: ReadonlyArray<null | {
                readonly id: string
                readonly color: 'BLUE' | 'CYAN' | 'RED' | 'YELLOW'
                readonly cost: number
                readonly objectives: ReadonlyArray<{
                  readonly BLUE?: number
                  readonly CYAN?: number
                  readonly RED?: number
                  readonly YELLOW?: number
                }>
              }>
              readonly players: ReadonlyArray<{
                readonly id: string
                readonly tiles: ReadonlyArray<{
                  readonly x: number
                  readonly y: number
                  readonly completedObjectivesMap: ReadonlyArray<boolean>
                  readonly tileDefinition: {
                    readonly id: string
                    readonly color: 'BLUE' | 'CYAN' | 'RED' | 'YELLOW'
                    readonly cost: number
                    readonly objectives: ReadonlyArray<{
                      readonly BLUE?: number
                      readonly CYAN?: number
                      readonly RED?: number
                      readonly YELLOW?: number
                    }>
                  }
                }>
                readonly position: {
                  readonly index: number
                  readonly order: number
                }
              }>
              readonly scoreLimit: number
              readonly gameOver?: boolean
              readonly winnerId?: string
            }
          }
        >
      | OperationResponse<403, { readonly message?: string }>
    parameters: { id: string }
    bodies:
      | { readonly type: 'ADD_PLAYER' }
      | { readonly type: 'START_GAME' }
      | {
          readonly type: 'PLACE_TILE'
          readonly index: number
          readonly x: number
          readonly y: number
        }
      | { readonly type: 'REFILL' }
  }
  createLobby: {
    responses:
      | OperationResponse<
          201,
          {
            readonly id: string
            readonly players: ReadonlyArray<{
              readonly id: string
              readonly nickname: string
              readonly avatar: string
              readonly ingameId?: string
            }>
            readonly gameState?: null | {
              readonly tileSupply: ReadonlyArray<{
                readonly id: string
                readonly color: 'BLUE' | 'CYAN' | 'RED' | 'YELLOW'
                readonly cost: number
                readonly objectives: ReadonlyArray<{
                  readonly BLUE?: number
                  readonly CYAN?: number
                  readonly RED?: number
                  readonly YELLOW?: number
                }>
              }>
              readonly tileWheel: ReadonlyArray<null | {
                readonly id: string
                readonly color: 'BLUE' | 'CYAN' | 'RED' | 'YELLOW'
                readonly cost: number
                readonly objectives: ReadonlyArray<{
                  readonly BLUE?: number
                  readonly CYAN?: number
                  readonly RED?: number
                  readonly YELLOW?: number
                }>
              }>
              readonly players: ReadonlyArray<{
                readonly id: string
                readonly tiles: ReadonlyArray<{
                  readonly x: number
                  readonly y: number
                  readonly completedObjectivesMap: ReadonlyArray<boolean>
                  readonly tileDefinition: {
                    readonly id: string
                    readonly color: 'BLUE' | 'CYAN' | 'RED' | 'YELLOW'
                    readonly cost: number
                    readonly objectives: ReadonlyArray<{
                      readonly BLUE?: number
                      readonly CYAN?: number
                      readonly RED?: number
                      readonly YELLOW?: number
                    }>
                  }
                }>
                readonly position: {
                  readonly index: number
                  readonly order: number
                }
              }>
              readonly scoreLimit: number
              readonly gameOver?: boolean
              readonly winnerId?: string
            }
          }
        >
      | OperationResponse<401, { readonly message?: string }>
    bodies: {}
  }
}

export interface OperationMap {
  queries: QueryMap
  mutations: MutationMap
}

export const createQueryEffect = <K extends keyof QueryMap>(oid: K) =>
  createQueryEffect_<QueryMap, K>(oid)
export const createMutationHook = <K extends keyof MutationMap>(oid: K) =>
  createMutationHook_<MutationMap, K>(oid)
