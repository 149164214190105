import { pipe } from 'fp-ts/function'
import React, { useCallback, useMemo, useState } from 'react'

import { GameState } from '@jaune/lune-game-core/lib/models/GameState'

import ErrorScreen from 'src/components/ErrorScreen'
import LoadingScreen from 'src/components/LoadingScreen'
import useLobbyQuery from 'src/hooks/useLobbyQuery'
import usePostLobbyActionMutation from 'src/hooks/usePostLobbyActionMutation'

import InGameScreen from '../InGameScreen'

import PlayerSlot from './PlayerSlot'
import withAuthenticationCheck from './hocs/withAuthenticationCheck'
import withLobbySlugCheck from './hocs/withLobbySlugCheck'

import scss from './style.scss'

export interface Lobby {
  readonly id: string
  readonly players: ReadonlyArray<{
    readonly id: string
    readonly nickname: string
    readonly avatar: string
    readonly ingameId?: string
  }>
  readonly gameState?: GameState | null
}

interface UnstartedGameScreenProps {
  lobby: Lobby
  lobbyChange: (lobby: Lobby) => void
}

const UnstartedGameScreen = ({
  lobby,
  lobbyChange,
}: UnstartedGameScreenProps) => {
  const lobbyId = lobby.id
  const [postLobbyAction, { pending, error }] = usePostLobbyActionMutation()

  const onStartGameClick = useCallback(() => {
    postLobbyAction({
      parameters: { id: lobbyId },
      body: {
        type: 'START_GAME',
      },
    }).then((data) => {
      if (data.status !== 200) {
        return
      }
      lobbyChange(data.body)
    })
  }, [postLobbyAction, lobbyChange, lobbyId])

  const onAddPlayer = useCallback(() => {
    postLobbyAction({
      parameters: { id: lobbyId },
      body: {
        type: 'ADD_PLAYER',
      },
    }).then((data) => {
      if (data.status !== 200) {
        return
      }
      lobbyChange(data.body)
    })
  }, [postLobbyAction, lobbyChange, lobbyId])

  return (
    <>
      <h1 className={scss.title}>Lune</h1>
      <pre>LobbyScreen # {lobby.id}</pre>
      {error && <pre>{JSON.stringify(error, null, 2)}</pre>}
      <div>
        {lobby.players.map((playerInfo, index) => (
          <PlayerSlot key={index} playerInfo={playerInfo} />
        ))}
      </div>
      <button onClick={onAddPlayer}>Add hotseat player</button>
      <button onClick={onStartGameClick} disabled={pending}>
        Start game
      </button>
      <button>Leave</button>
    </>
  )
}

interface Props {
  slug: string
}

const LobbyScreen = pipe(
  ({ slug }: Props) => {
    const opts = useMemo(() => ({ parameters: { id: slug } }), [slug])
    const { data, pending, error } = useLobbyQuery(opts)

    const [lobbyState, setLobbyState] = useState<Lobby | null>(null)

    const lobby = useMemo(() => {
      if (lobbyState) {
        return lobbyState
      }

      if (data && data.status === 200) {
        return data.body
      }

      return null
    }, [data, lobbyState])

    const ownedIds = useMemo(() => {
      if (!lobby) {
        return []
      }

      return lobby.players
        .map((player) => player.ingameId)
        .filter((id) => !!id) as string[]
    }, [lobby])

    if (pending) {
      return <LoadingScreen />
    }

    if (!lobby || error) {
      return <ErrorScreen error={error} />
    }

    if (lobby && lobby.gameState) {
      return (
        <InGameScreen
          lobbyId={lobby.id}
          ownedPlayersIds={ownedIds}
          gameState={lobby.gameState}
          lobbyChange={setLobbyState}
        />
      )
    }

    return <UnstartedGameScreen lobby={lobby} lobbyChange={setLobbyState} />
  },
  withAuthenticationCheck(),
  withLobbySlugCheck()
)

export default LobbyScreen
