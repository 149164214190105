import { useEffect, useState } from 'react'

import { useContext } from '../index'
import { OperationDefinitionMap, QueryOptions, QueryResult } from '../types'

const createQueryHook = <
  OperationMap extends OperationDefinitionMap,
  K extends keyof OperationMap
>(
  operationId: K
) => (
  options: QueryOptions<OperationMap[K]> | null = null
): QueryResult<OperationMap[K]['responses']> => {
  const { ensureQuerySubscribable } = useContext()
  const [result, setResult] = useState<
    QueryResult<OperationMap[K]['responses']>
  >({ pending: true })

  useEffect(() => {
    const obs = ensureQuerySubscribable<OperationMap, K>(operationId, options)

    const subscription = obs.subscribe({
      next: (data) => {
        setResult({
          data,
        })
      },
      error: (error) => {
        setResult({
          error,
        })
      },
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [setResult, ensureQuerySubscribable, options])

  return result
}

export default createQueryHook
